import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import ServicesPageBanner from '../components/Services/ServicesPageBanner'
import Footer from "../components/App/Footer"
import RecentProjects from "../components/Index/RecentProjects"
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

const Services = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Services" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/products" />

        </Helmet>
            <Navbar />
            <ServicesPageBanner
                pageTitle="Products" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Products" 
            />
             <RecentProjects />
            <Footer />
        </Layout>
    );
}

export default Services